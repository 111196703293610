$(document).ready(function () {
	jQuery.fn.exists = function () {
		return this.length > 0;
	};

	$('.menu ul.menu>li>h4').hover(
		function () {
			console.log("lo");
			$(this).parents('.menu').find('.sub-menu').removeClass("show");
			if ($(this).data('ssmenu') != "") {
				//$('#main-nav #'+$(this).data('ssmenu')).css('top', $('#main-nav').position().top + 60);
				$(this).parent().find('.sub-menu').addClass("show");
				$(this).parent().find('.sub-menu').hover(function () {
				}, function () {
					$(this).removeClass("show");
				});
			}
		},
		function () {
			//$(this).parent().find('.submenu').hide();
		}
	);

	//Modal Menu
	$('.modal-body.menu .menu-item-has-children').unbind('click').bind('click', function (e) {
		console.log("ici");
		$(this).find('.sub-menu').addClass('show');
		//e.preventDefault();
	});

	$('.wpcf7-mail-sent-ok').click(function()
	{
		$(this).hide();
	});

	function testScroll(ev)
	{
		if(window.pageYOffset > ($(".header").height() - 50)) {
			if(!$(".header").hasClass("small"))
			{
				$("#page").css("margin-top", $(".header").height() + "px");
				$(".header").addClass("small");
			}
		} else {
			$("#page").css("margin-top",0);
			$(".header").removeClass("small");
		}
	}
	window.onscroll = testScroll;

	$('.slideshow:has(.hover)').hover(
		function()
		{
			$(this).find(".carousel").carousel('pause');
		},
		function()
		{
			$(this).find(".carousel").carousel('cycle');
		}
	);

	$(".carousel").carousel({
		interval: 2500
	});

	var pinterest = $('.pinterest-like').masonry({
		// options
		itemSelector: '.pinterest-item',
		columnWidth: 324,
		gutter: 10,
		fitWidth: true
	});

	pinterest.imagesLoaded().progress( function() 
	{
		pinterest.masonry('layout');
	});

});


$(document).ready(function() 
{

	$('.owl-carousel').owlCarousel(
	{
		loop:true,
		margin:10,
		nav:false,
		mouseDrag:false,
		touchDrag:false,
		responsive:
		{
			0:{
				items:1
			},
			600:{
				items:3
			},
			1000:{
				items:5
			}
		}
	});

	$('.gallerie #myCarousel .left').click(function()
	{
		$('.owl-carousel').trigger('prev.owl.carousel');
	});
	$('.gallerie #myCarousel .right').click(function()
	{
		$('.owl-carousel').trigger('next.owl.carousel');
	});
	$('owl-carousel .link').off().click(function()
    {
    	console.log("oihjd");
		var container = $('<div class="zoom-image"></div>');
		container.append($(this).find('img').clone());
		$('body').append(container);
		
		$('.zoom-image').css('height', $(window).height()+'px').css('top', $(window).scrollTop()+'px');
		$(window).scroll(function()
		{
			$('.zoom-image').css('top', $(window).scrollTop()+'px');
		});
		$('.zoom-image img').css('max-height', ($(window).height()*0.8)+'px');

		$('.zoom-image').click(function()
		{
			$(this).remove();
		});
    });

});

$(document).ready(function() 
{
	function changeCalendar()
	{
		$("#calendar").calendarWidget(
		{
			month: parseInt($("#calendar").data('month')) - 1,
			year: $("#calendar").data('year')
		});
		var mois = $("#calendar h3").text();
		$("#calendar h3").remove();
		$("#calendar").prepend($('<div class="header-calendar"><div class="bt-change-month float-left"><img src="/wp-content/themes/chaletlacha/images/gauche.png"/></div><div class="h3"><h3>'+mois+'</h3></div><div class="bt-change-month float-right"><img src="/wp-content/themes/chaletlacha/images/droit.png"/></div></div>'));
		initBoutonCalendar();
		iniDatesIndispo();
	}

	var dates = $(".disponibilites .dates .date");
	function iniDatesIndispo()
	{
		$.each(dates, function(index, date)
		{
			aDepart = $(date).data('depart').split('/');
			aFin = $(date).data('fin').split('/');
			//console.log(aDepart, aFin);
			if(parseInt(aDepart[2]) == $("#calendar").data('year') || parseInt(aFin[2]) == $("#calendar").data('year'))
			{
				//console.log(parseInt(aDepart[1]), (parseInt($("#calendar").data('month'))));
				if(parseInt(aDepart[1]) == (parseInt($("#calendar").data('month'))) || parseInt(aFin[1]) == parseInt($("#calendar").data('month')))
				{
					//console.log(aDepart, aFin);
					var fin = new Date(aFin[2], aFin[1]-1, aFin[0]);
					var daysOfYear = [];
					for (var d = new Date(aDepart[2], aDepart[1]-1, aDepart[0]); d <= fin; d.setDate(d.getDate() + 1))
					{
						//console.log(d);
						if(d.getMonth() == parseInt($("#calendar").data('month')) -1 )
						{
							$("#calendar td.day"+d.getDate()).addClass('indispo');
						}
						else 
						{
							
							$.each($("#calendar td.other-month"), function(index, tdday)
							{
								if(d.getDate() == parseInt($(tdday).find('.day').text()))
								{
									//console.log($(tdday).parents('.other-month'));
									$(tdday).addClass('indispo');
								}
							});
						}
					}
				}
			}
		});
	}

	function initBoutonCalendar()
	{
		$("#calendar .bt-change-month").click(function()
		{
			var increment = +1;
			if($(this).hasClass('float-left'))
				increment = -1;

			var mois = parseInt($("#calendar").data('month'));
			var year = parseInt($("#calendar").data('year'));

			mois += increment;
			if(mois == 12)
			{
				mois = 0;
				year += 1
			}
			if(mois == -1)
			{
				mois = 11;
				year -= 1;
			}
			$("#calendar").data('month', mois);
			$("#calendar").data('year', year);

			$("#calendar").calendarWidget(
			{
				month: $("#calendar").data('month'),
				year: $("#calendar").data('year')
			});
			changeCalendar();
		});
	}
	changeCalendar();
});

//SLIDESHOW
$(document).ready(function(e) 
{
	var pourcentageHeight = 0.7;
	$('#slideshow').width(($('#slideshow .visuel-principal').length * 100) + '%');

	$('#slideshow .visuel-principal').height($(window).height() * pourcentageHeight);
	$('#slideshow .visuel-principal').width($(window).width());
	$('#slideshow .control').height($(window).height() * pourcentageHeight);
	$('#slideshow .control .fleche').css('marginTop', (parseInt($('#slideshow .control').height()) * 0.5 - parseInt($('#slideshow .control .fleche img').height()) / 2)+'px');
	$('#slideshow .control .fleche.fleche-bas').css('left', (parseInt($('#slideshow .control').width() / 2) - parseInt($('#slideshow .control .fleche img').width()) / 2)+'px');

	var slideshow = setInterval(function()
	{
		changeSlideshow(1);
	}, 5000);
	function changeSlideshow(sens)
	{
		$("#slideshow .visuel-principal:first").stop( false, false );
		if(sens == -1)
		{
			if(parseInt($("#slideshow .visuel-principal:first").css('marginLeft')) == 0)
			{
				$("#slideshow .visuel-principal:first").css('marginLeft', '0px')
				$("#slideshow .visuel-principal:last").after($("#slideshow .visuel-principal:first"));
				$("#slideshow .visuel-principal:first").css('marginLeft', -parseInt($(window).width())+'px')
			}
			$("#slideshow .visuel-principal:first").animate({marginLeft:'0px' }, 1500, "swing", function()
			{
				$("#slideshow .visuel-principal:first").css('marginLeft', '0px')
				$("#slideshow .visuel-principal:last").after($("#slideshow .visuel-principal:first"));
				$("#slideshow .visuel-principal:first").css('marginLeft', -parseInt($(window).width())+'px')
			});
		}
		else
		{
			$("#slideshow .visuel-principal:first").animate({marginLeft:( -parseInt($(window).width()))+'px' }, 1500, "swing", function()
			{
				$(this).css({marginLeft:0}).parents('#slideshow').find(".visuel-principal:last").after($(this).parents('#slideshow').find(".visuel-principal:first"));
			});
		}
	}
	$( window ).resize(function() 
	{
		$('#slideshow .visuel-principal').height($(window).height() * pourcentageHeight);
		$('#slideshow .visuel-principal').width($(window).width());
		var decalageDiaporama = parseInt($(window).width());
		$('#slideshow .control').height($(window).height() * pourcentageHeight);
		$('#slideshow .control .fleche').css('marginTop', (parseInt($('#slideshow .control').height()) * 0.5 - parseInt($('#slideshow .control .fleche img').height()) / 2)+'px');
		$('#slideshow .control .fleche.fleche-bas').css('left', (parseInt($('#slideshow .control').width() / 2) - parseInt($('#slideshow .control .fleche img').width()) / 2)+'px');
	});
	$('#slideshow .fleche').click(function()
	{
		clearInterval(slideshow);
		
		if($(this).hasClass('fleche-gauche'))
		{
			sens = -1;
			
		}
		if($(this).hasClass('fleche-droite'))
			sens = 1;
		changeSlideshow(sens);

	});
	$('#slideshow .fleche.fleche-bas').off().click(function()
	{
		$('html, body').animate({
			scrollTop: $("#description-1").position().top 
		}, 1000);
	});

});


//SLICK
$(document).ready(function(e) 
{
	$('.slick').slick({
		dots: true,
		speed: 1000,
		arrows : true,
		autoplay : true,
		centerMode : false,
		/*centerPadding : '10%',*/
		draggable : true,
		infinite : true,
		mobileFirst: true,
		slidesPerRow: 1,
		rowws : 1,
		prevArrow : '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><img src="/wp-content/themes/chaletlacha/images/gauche.png"></button>',
		nextArrow : '<button class="slick-next slick-arrow" aria-label="Previous" type="button" style=""><img src="/wp-content/themes/chaletlacha/images/droit.png"></button>'
	});
});

//ANTISPAM
$(document).ready(function(e) 
{
	var sourisbouge = false;
	function chargeNoBot()
	{
		$.ajax({
			url: '/wp-content/themes/chaletlacha/services/setNobot.php',
			success: function(data)
			{
			},
			error: function (xhr, ajaxOptions, thrownError)
			{
				chargeNoBot();
			}
		});
	}
	$('body').mousemove(function() 
	{
		if(sourisbouge == false)
		{
			sourisbouge = true;
			chargeNoBot();
		}
	});
});


//formulaire de resa
$(document).ready(function(e) 
{
	$( "#date_arrive" ).datepicker($.datepicker.regional[ "fr" ]);
	$( "#date_depart" ).datepicker($.datepicker.regional[ "fr" ]);

	$('form[name=resa] a.envoyer').click(function()
	{
		var date_arrive = $( "#date_arrive" ).val();
		var date_depart = $( "#date_depart" ).val();
		var personnes = $('#personnes').val();
		var animal = $('[name=animal]:checked').val();
		var nom = $('[name=nom]').val();
		var prenom = $('[name=prenom]').val();
		var email = $('[name=email]').val();

		var error = false;
		if(animal == undefined)
		{
			error = true;
			$('.animal').css('color', 'red');
		}
		else
		{
			$('.animal').css('color', '');
		}

		if(nom == "")
		{
			error = true;
			$('[name=nom]').parents('label').css('color', 'red');
		}
		else
		{
			$('[name=nom]').parents('label').css('color', '');
		}

		if(prenom == "")
		{
			error = true;
			$('[name=prenom]').parents('label').css('color', 'red');
		}
		else
		{
			$('[name=prenom]').parents('label').css('color', '');
		}

		if(email == "")
		{
			error = true;
			$('[name=email]').parents('label').css('color', 'red');
		}
		else
		{
			$('[name=email]').parents('label').css('color', '');
		}

		if(personnes == "")
		{
			error = true;
			$('#personnes').css('border-color', 'red');
		}
		else
		{
			$('#personnes').css('border-color', '');
		}

		if(date_arrive == "")
		{
			error = true;
			$('#date_arrive').css('border-color', 'red');
		}
		else
		{
			$('#date_arrive').css('border-color', '');
		}

		if(date_depart == "")
		{
			error = true;
			$('#date_depart').css('border-color', 'red');
		}
		else
		{
			$('#date_depart').css('border-color', '');
		}

		if(error == false)
		{
			//envoi du mail
			var data = {
				date_arrive : date_arrive,
				date_depart : date_depart,
				personnes : personnes,
				animal : animal,
				nom : nom,
				prenom : prenom,
				email : email,
				gite : $('select[name=gite] option:selected').text(),
				message : $('textarea[name=message]').val()
			}

			$.ajax({
				url: '/wp-content/themes/chaletlacha/services/sendresa.php',
				data:data,
				type: "post",
				success: function(data)
				{
					console.log(data);
					$('form[name=resa]').hide();
					$('.merci').removeClass('hide');
				},
				error: function (xhr, ajaxOptions, thrownError)
				{
					//chargeNoBot();
				}
			});
		}
	});

});

